<template>
    <div>
        <v-row>
            <v-col
                cols="4"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="Date Enrolled"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="moa.cover_date_header"
                    :rules="genericRule"
                    ref="date_enrollment"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="4"
                sm="4"
                md="4"
            >
                <v-select
                dense
                :items="items"
                label="Salutation"
                outlined
                v-model="moa.salutation"
                @change="addSalutation"
                :rules="genericRule"
                ></v-select>
            </v-col>
            <v-col
                cols="4"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="Authorized Signatory Surname"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="moa.cover_dear"
                    :rules="genericRule"
                    ref="signatory_surname"
                ></v-text-field>
            </v-col>  
        </v-row>
        <v-row>
            <v-col
                cols="4"
                sm="6"
                md="4"
                >
                <v-text-field
                    label="Day of Execution"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="moa.day_execution"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="4"
                sm="6"
                md="4"
                >
                <v-text-field
                    label="Month of Execution"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="moa.month_execution"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="4"
                sm="6"
                md="4"
                >
                <v-text-field
                    label="Year of Execution"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="moa.year_execution"
                    type="number"
                    hide-spin-buttons
                    readonly
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Midnight Date"
                    v-model="moa.midnight_date"
                    outlined
                    dense
                    hide-details="auto"
                    readonly
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Address/Location of Execution"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="moa.address_execution"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Account Name"
                    v-model="avp_details.primary_details.account_name"
                    outlined
                    readonly
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                    ref="company_name"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Address Line 1"
                    readonly
                    v-model="avp_details.primary_business_address.address_line_1"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    readonly
                    label="Address Line 2"
                    v-model="avp_details.primary_business_address.address_line_2"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    label="Country"
                    readonly
                    v-model="avp_details.primary_business_address.country"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    label="Province"
                    readonly
                    v-model="avp_details.primary_business_address.province"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    label="City"
                    readonly
                    v-model="avp_details.primary_business_address.city"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
                >
                <v-text-field
                    label="Region"
                    readonly
                    v-model="avp_details.primary_business_address.region"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="12"
                sm="6"
                md="6"
                >
                <v-text-field
                    label="Postal Code"
                    readonly
                    v-model="avp_details.primary_business_address.postal_code"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
                >
                <v-text-field
                    label="Landline"
                    readonly
                    v-model="avp_details.primary_business_address.landline"
                    outlined
                    dense
                    hide-details="auto"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="12"
                sm="6"
                md="6"
                >
                <v-text-field
                    label="Cellphone Number"
                    readonly
                    v-model="avp_details.primary_business_address.cellphone"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>


        <v-row class="mt-5">
            <v-col>
                <h4>AVP Products/Services to Avail</h4>
            </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-checkbox
              label="Select All"
              color="indigo"
              hide-details="auto"
              false-value="0"
              true-value="1"
              @change="selectAllToggle"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >

            <v-checkbox
                v-if="moa.is_dengue_generated == 1"
                label="DENGUE PROTECT PLUS (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_dengue"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_dengue == false || moa.is_dengue == true"
                label="DENGUE PROTECT PLUS"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_dengue"
            ></v-checkbox>

            <!-- <v-checkbox
                v-if="moa.is_payapa_generated == 1"
                label="PERA PAYAPA (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_payapa"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_payapa == false || moa.is_payapa == true"
                label="PERA PAYAPA"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_payapa"
            ></v-checkbox> -->

            
            <v-checkbox
                v-if="moa.is_payapa2_generated == 1"
                label="PERA PAYAPA 2.0 (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_payapa2"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_payapa2 == false || moa.is_payapa2 == true"
                label="PERA PAYAPA 2.0"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_payapa2"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_microsafe_generated == 1"
                label="MICROSAFE PA (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_microsafe"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_microsafe == false || moa.is_microsafe == true"
                label="MICROSAFE PA"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_microsafe"
            ></v-checkbox>
            
            <v-checkbox
                v-if="moa.is_malayan_generated == 1"
                label="MALAYAN PERSONAL ACCIDENT INSURANCE (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_malayan"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_malayan == false || moa.is_malayan == true"
                label="MALAYAN PERSONAL ACCIDENT INSURANCE"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_malayan"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_visa_selling_generated == 1"
                label="PERAHUB VISA CARD SELLING (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_visa_selling"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_visa_selling == false || moa.is_visa_selling == true"
                label="PERAHUB VISA CARD SELLING"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_visa_selling"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_visa_cashin_generated == 1"
                label="PERAHUB VISA CARD CASH-IN (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_visa_cashin"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_visa_cashin == false || moa.is_visa_cashin == true"
                label="PERAHUB VISA CARD CASH-IN"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_visa_cashin"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="4"
          >
          <v-checkbox
                v-if="moa.is_ctpl_generated == 1"
                label="CTPL (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_ctpl"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_ctpl == false || moa.is_ctpl == true"
                label="CTPL"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_ctpl"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_gcash_in_generated == 1"
                label="GCASH - CASH-IN (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_gcash_in"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_gcash_in == false || moa.is_gcash_in == true"
                label="GCASH - CASH-IN"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_gcash_in"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_gcash_out_generated == 1"
                label="GCASH - CASH-OUT (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_gcash_out"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_gcash_out == false || moa.is_gcash_out == true"
                label="GCASH - CASH-OUT"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_gcash_out"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_paymaya_generated == 1"
                label="PAYMAYA ADD MONEY / CASH-IN (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_paymaya"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_paymaya == false || moa.is_paymaya == true"
                label="PAYMAYA ADD MONEY / CASH-IN"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_paymaya"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_coins_generated == 1"
                label="COINS CASH-IN (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_coins"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_coins == false || moa.is_coins == true"
                label="COINS CASH-IN"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_coins"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_dragonpay_generated == 1"
                label="DRAGONPAY CASH PAYOUT / DISBURSEMENT (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_dragonpay"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_dragonpay == false || moa.is_dragonpay == true"
                label="DRAGONPAY CASH PAYOUT / DISBURSEMENT"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_dragonpay"
            ></v-checkbox>

          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="4"
          >
          <v-checkbox
                v-if="moa.is_aboitiz_generated == 1"
                label="Aboitiz FG (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_aboitiz"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_aboitiz == false || moa.is_aboitiz == true"
                label="Aboitiz FG"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_aboitiz"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_rcbc_generated == 1"
                label="RCBC ATM Go (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_rcbc"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_rcbc == false || moa.is_rcbc == true"
                label="RCBC ATM Go"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_rcbc"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_unionbank_generated == 1"
                label="UNIONBANK CASH-IN / DEPOSIT TO ACCOUNT SERVICE (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_unionbank"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_unionbank == false || moa.is_unionbank == true"
                label="UNIONBANK CASH-IN / DEPOSIT TO ACCOUNT SERVICE"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_unionbank"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_eload_generated == 1"
                label="eLOAD / e-GAMES (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_eload"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_eload == false || moa.is_eload == true"
                label="eLOAD / e-GAMES"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_eload"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_airline_generated == 1"
                label="AIRLINE TICKETING (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_airline"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_airline == false || moa.is_airline == true"
                label="AIRLINE TICKETING"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_airline"
            ></v-checkbox>

            <v-checkbox
                v-if="moa.is_bills_pay_generated == 1"
                label="BILLS PAYMENT (available soon) (✔)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_bills_pay"
            ></v-checkbox>
            <v-checkbox
                v-else-if="moa.is_bills_pay == false || moa.is_bills_pay == true"
                label="BILLS PAYMENT (available soon)"
                color="indigo"
                hide-details="auto"
                false-value="0"
                true-value="1"
                v-model="moa.is_bills_pay"
            ></v-checkbox>
            
          </v-col>
        </v-row>

        <v-row>
            <v-col
           cols="12"
            sm="2"
            md="2">
            <v-checkbox
              label="Others:"
              color="indigo"
              hide-details="auto"
              false-value="0"
              true-value="1"
              v-model="moa.is_others"
              @change="othersToggle"
            ></v-checkbox>
          </v-col>
          <v-col
           cols="12"
            sm="8"
            md="8">
            <!-- <v-text-field
                v-if="moa.is_others != (0 || false)"
                v-model="moa.other_products"
            ></v-text-field> -->

            <v-combobox
                v-if="moa.is_others != (0 || false)"
                v-model="moa.other_products_array"
                hide-selected
                multiple
                persistent-hint
                small-chips
                item-color="primary"
            >
            </v-combobox>
            
          </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn
                v-if="moa.is_airline != 0 || moa.is_bills_pay != 0 || moa.is_coins != 0 || moa.is_dengue != 0 || moa.is_dragonpay != 0 || moa.is_eload != 0 || moa.is_gcash_in != 0 || moa.is_gcash_out != 0 || moa.is_malayan != 0 || moa.is_paymaya != 0 || moa.is_unionbank != 0 || moa.is_visa_cashin != 0 || moa.is_visa_selling != 0 || moa.is_payapa != 0 || moa.is_payapa2 != 0 || moa.is_microsafe != 0 || moa.is_rcbc != 0 || moa.is_ctpl != 0 || moa.is_aboitiz != 0"
                class="mb-4" style="float:right;" @click="generateOperationalReq" color="primary"><v-icon>mdi-plus</v-icon>Generate Operational Requirements</v-btn>
                <v-btn
                v-else
                disabled
                class="mb-4" style="float:right;" @click="generateOperationalReq" color="primary"><v-icon>mdi-plus</v-icon>Generate Operational Requirements</v-btn>
            </v-col>
        </v-row>



        <v-row>
            <v-col>
                <h4>Sub-representative</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="3"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="First Name"
                    v-model="moa.sub_representative.first_name"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="3"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="Middle Name"
                    v-model="moa.sub_representative.middle_name"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <v-col
                cols="3"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="Last Name"
                    v-model="moa.sub_representative.last_name"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <v-col
                cols="3"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="Suffix"
                    v-model="moa.sub_representative.suffix"
                    outlined
                    dense
                    hide-details="auto"
                ></v-text-field>
            </v-col>  
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Position"
                    v-model="moa.sub_representative.position"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
        </v-row>
        <v-row>
            <v-col
                cols="6"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="ID Type"
                    v-model="moa.sub_representative.id_type"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <v-col
                cols="6"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="ID Number"
                    v-model="moa.sub_representative.id_number"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <v-col :cols="4">
                <v-menu
                    v-model="moa.sub_representative.menuIssued"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    hide-details="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="moa.sub_representative.id_expiry"
                                label="ID Expiry"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                hide-details="auto"
                                :rules="genericRule"
                            >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="moa.sub_representative.id_expiry"
                       @input="moa.sub_representative.menuIssued = false"
                        hide-details="auto"
                        >
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h4>Petnet Inc.</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Name"
                    v-model="moa.pet_name"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>
        <v-row>
            <v-col
                cols="6"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="ID Type"
                    v-model="moa.pet_id_type"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <v-col
                cols="6"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="ID Number"
                    v-model="moa.pet_id_number"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <v-col
                :cols="4"
                >
                <v-text-field
                    label="ID Expiry"
                    v-model="moa.pet_id_expiry"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col>  
            <!-- <v-col :cols="4">
                <v-menu
                    v-model="moa.pet_menuIssued"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    hide-details="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="moa.pet_id_expiry"
                                label="ID Expiry"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                hide-details="auto"
                                :rules="genericRule"
                            >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="moa.pet_id_expiry"
                       @input="moa.pet_menuIssued = false"
                        hide-details="auto"
                        >
                    </v-date-picker>
                </v-menu>
            </v-col> -->
        </v-row>

        <v-row>
            <v-col>
                <h4>Contact Person</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                    label="Name"
                    v-model="moa.cover_name"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="Position"
                    v-model="moa.cover_position"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="12"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="Department"
                    v-model="moa.cover_department"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col
                cols="12"
                sm="4"
                md="4"
                >
                <v-text-field
                    label="Cellphone Number"
                    v-model="moa.cover_number"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>

        <v-row>
            <v-col>
                <h4>To Email</h4>
            </v-col>
        </v-row>

        <v-combobox
            v-model="moa.to_email"
            hide-selected
            multiple
            persistent-hint
            small-chips
            item-color="primary"
        >
        </v-combobox>

        <v-row>
            <v-col>
                <h4>CC</h4>
            </v-col>
        </v-row>

        <v-combobox
            v-model="moa.cc_email"
            hide-selected
            multiple
            persistent-hint
            small-chips
            item-color="primary"
        >
        </v-combobox>
        <v-row>
            <v-col>
                <h4>BCC</h4>
            </v-col>
        </v-row>

        <v-combobox
            v-model="moa.bcc_email"
            hide-selected
            multiple
            persistent-hint
            small-chips
            item-color="primary"
        >
        </v-combobox>

        <v-row>
            <v-col>
                <v-btn
                class="mb-4" style="float:right;" @click="uploadAvp"  color="primary"><v-icon>mdi-plus</v-icon>Update AVP Details</v-btn>
            </v-col>
        </v-row>


    </div>
</template>
<script>

import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';

export default {
    props: ["item"],
    data () {
      return {
        others: false,
        items: ['Mr.','Ms.','Mrs.'],
        company_name: false,
        signatory_surname: false,
        date_enrollment: false,
      }
    },

    computed: {
    ...mapGetters({
            avp: 'salesProspecting/avp',
            moa: 'salesProspecting/moa',
            genericRule: 'salesProspecting/genericRule',
            avp_details: 'salesProspecting/avp_details',
            existingSignatoryUploaded: 'salesProspecting/existingSignatoryUploaded'
        }),
	},
    methods: {
        async uploadAvp(){
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
            this.$store.commit('salesProspecting/SET_RULE_GENERIC')
            if (this.moa.moa_type == 'AVP'){
                    setTimeout(async () => {
                        //validate if date of enrollment, company name, and authorized signatory name is populated
                        if (this.$refs.signatory_surname.validate() && this.$refs.company_name.validate() && this.$refs.date_enrollment.validate()){ 
                            if( this.checkSalutationWithName(this.moa.cover_dear)){ //validate if only salutation is entered
                                var convertedFormData = jsonToFormData(this.moa, options)
                                const payload = {id: this.item, payload: convertedFormData}
                                const res = await this.$store.dispatch('salesProspecting/doUploadAvp', {payload})
                                if (res.status === 200){
                                    this.realoadAvp()
                                }
                            }else{
                                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': `No name found after the salutation.`,'messagetype':'error'},{ root: true });
                            }

                        } else {
                            this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
                        }
                    })
                }

        },
        async generateOperationalReq(){
            this.$store.commit('salesProspecting/SET_RULE_GENERIC')
            //validations for signatory is populated
            if (!this.existingSignatoryUploaded || this.existingSignatoryUploaded.length === 0) {
            this.$store.commit('app/SET_SNACK_BAR', {
                'status': true,
                'message': 'Signatory is required before proceeding',
                'messagetype': 'error'
            }, { root: true });
            } else if (this.moa.moa_type == 'AVP'){
                    setTimeout( async () => {
                            if(this.$refs.company_name.validate()){ //validate if company name is populated
                                if(this.$refs.date_enrollment.validate()){ //validate if date of enrollment is populated
                                    if (!this.moa.to_email){
                                        this.moa.to_email = []
                                    } 
                                    if (!this.moa.cc_email){
                                        this.moa.cc_email = []
                                    } 
                                    if (!this.moa.bcc_email){
                                        this.moa.bcc_email = []
                                    }

                                    if (this.moa.other_products_array){
                                        let otherProd = this.moa.other_products_array.join(', ')
                                        this.moa.other_products = otherProd
                                    }
                                    this.moa.is_send = false
                                    this.moa.is_op_req_only = true

                                    if (this.moa.is_dengue === '1'){
                                        this.moa.is_dengue_generated = true
                                    }
                                    if (this.moa.is_payapa === '1'){
                                        this.moa.is_payapa_generated = true
                                    }
                                    //Added Condition for generating new Operational Requirements
                                    if (this.moa.is_payapa2 === '1'){
                                        this.moa.is_payapa2_generated = true
                                    }
                                    if (this.moa.is_microsafe === '1'){
                                        this.moa.is_microsafe_generated = true
                                    }
                                    if (this.moa.is_aboitiz === '1'){
                                        this.moa.is_aboitiz_generated = true
                                    }
                                    if (this.moa.is_rcbc === '1'){
                                        this.moa.is_rcbc_generated = true
                                    }
                                    if (this.moa.is_ctpl === '1'){
                                        this.moa.is_ctpl_generated = true
                                    }
                                    if (this.moa.is_malayan == '1'){
                                        this.moa.is_malayan_generated = true
                                    }
                                    if (this.moa.is_visa_selling == '1'){
                                        this.moa.is_visa_selling_generated = true
                                    }
                                    if (this.moa.is_visa_cashin == '1'){
                                        this.moa.is_visa_cashin_generated = true
                                    }
                                    if (this.moa.is_gcash_in == '1'){
                                        this.moa.is_gcash_in_generated = true
                                    }
                                    if (this.moa.is_gcash_out == '1'){
                                        this.moa.is_gcash_out_generated = true
                                    }
                                    if (this.moa.is_paymaya == '1'){
                                        this.moa.is_paymaya_generated = true
                                    }
                                    if (this.moa.is_coins == '1'){
                                        this.moa.is_coins_generated = true
                                    }
                                    if (this.moa.is_dragonpay == '1'){
                                        this.moa.is_dragonpay_generated = true
                                    }
                                    if (this.moa.is_unionbank == '1'){
                                        this.moa.is_unionbank_generated = true
                                    }
                                    if (this.moa.is_eload == '1'){
                                        this.moa.is_eload_generated = true
                                    }
                                    if (this.moa.is_airline == '1'){
                                        this.moa.is_airline_generated = true
                                    }
                                    if (this.moa.is_bills_pay == '1'){
                                        this.moa.is_bills_pay_generated = true
                                    }

                                    this.moa.is_op_req_only = true;
                                    const initialPayload = this.moa
                                    const payload = {id: this.item, payload: initialPayload}
                                    const res = await this.$store.dispatch('salesProspecting/doGenerateMoa', {payload})
                                    this.pdfConvert(res.data.result)
                                }else{
                                    this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Date Enrollment is required!','messagetype':'error'},{ root: true });
                                }
                            }else{
                                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Company Name is required!','messagetype':'error'},{ root: true });
                            }
                    })
                }
            
            // this.moa.is_op_req_only = true;
            // const initialPayload = this.moa
            // const payload = {id: this.item, payload: initialPayload}
            // const res = await this.$store.dispatch('salesProspecting/doGenerateMoa', {payload})
            // this.pdfConvert(res.data.result)
        },
        pdfConvert (base64String) {
            var decoded = Buffer.from(base64String, 'base64')
            var blob = new Blob([decoded], { type: 'application/pdf' })
            var fileURL = URL.createObjectURL(blob)
            window.open(fileURL)
        },
        async realoadAvp () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item)
			this.decodeAvp(res.data.result[0].avp_moa.fields)
		},
        decodeAvp (resBod){
            const avpDetails = JSON.parse(resBod)
            avpDetails.pet_menuIssued = false
            avpDetails.sub_representative.menuIssued = false
			this.$store.commit('salesProspecting/SET_MOA', avpDetails)
		},
        othersToggle(value){
            if (value == 0 ){
                this.moa.other_products = '',
                this.moa.other_products_array = []
            }

        },
        selectAllToggle(value){
            if (value == (1 || true)){
                this.moa.is_dengue= '1',
                this.moa.is_payapa= '1',
                //Added new Operational Requirements
                this.moa.is_payapa2= '1',
                this.moa.is_microsafe= '1',
                this.moa.is_ctpl= '1',
                this.moa.is_aboitiz= '1',
                this.moa.is_rcbc= '1',
                this.moa.is_malayan= '1',
                this.moa.is_visa_selling= '1',
                this.moa.is_visa_cashin= '1',
                this.moa.is_gcash_in= '1',
                this.moa.is_gcash_out= '1',
                this.moa.is_paymaya= '1',
                this.moa.is_coins= '1',
                this.moa.is_dragonpay= '1',
                this.moa.is_unionbank= '1',
                this.moa.is_airline= '1',
                this.moa.is_eload='1',
                this.moa.is_bills_pay= '1'
            } else if (value == (0 || false) ){
                this.moa.is_dengue= '0',
                this.moa.is_payapa= '0',
                //Added new Operational Requirements
                this.moa.is_payapa2= '0',
                this.moa.is_microsafe= '0',
                this.moa.is_ctpl= '0',
                this.moa.is_aboitiz= '0',
                this.moa.is_rcbc= '0',
                this.moa.is_malayan= '0',
                this.moa.is_visa_selling= '0',
                this.moa.is_visa_cashin= '0',
                this.moa.is_gcash_in= '0',
                this.moa.is_gcash_out= '0',
                this.moa.is_paymaya= '0',
                this.moa.is_coins= '0',
                this.moa.is_dragonpay= '0',
                this.moa.is_unionbank= '0',
                this.moa.is_airline= '0',
                this.moa.is_eload='0',
                this.moa.is_bills_pay= '0'
            }
        },
        addSalutation(item){
            this.moa.cover_dear = ''
            this.moa.cover_dear = item + ' ' + this.moa.cover_dear
        },
        //checker if authorized signatory has a name
        checkSalutationWithName(name) {
            const salutations = ['Mr.', 'Ms.', 'Mrs.'];
            const pattern = new RegExp(`^(${salutations.join('|')}),?\\s+([A-Za-z]+)?`, 'i');

            const match = name.match(pattern);
            
            if (match && match[2]) {
                return true
            } else if (match) {
                return false
            } else {
                return false
            }
        }

    }
}
</script>
